import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { makeGqlCall, Domain } from "../../../apiClient";
import {
  DocumentInventoryTypeFragment,
  fetchDocumentInventoryTypes,
} from "./useDocumentInventoryTypes";

export const disableDocumentInventoryTypeQuery = /* GraphQL */ `
  mutation ($input: DisableDocumentInventoryTypeInput!) {
    disableDocumentInventoryType(input: $input) {
      query {
        documentInventoryTypes {
          ...DocumentInventoryTypeFragment
        }
      }
    }
  }
  ${DocumentInventoryTypeFragment}
`;

const disableDocumentInventoryType = makeGqlCall<{}, { input: { id: string } }>(
  {
    query: disableDocumentInventoryTypeQuery,
    domain: Domain.DocumentManagement,
  }
);

export const useDisableDocumentInventoryType = () => {
  const queryClient = useQueryClient();

  return useMutation(disableDocumentInventoryType, {
    onSuccess: () => {
      message.success("Document inventory type was disabled");
      queryClient.invalidateQueries(fetchDocumentInventoryTypes.name);
    },
  });
};
