import { useQuery } from "react-query";
import { makeGqlCall, Domain } from "../../../apiClient";

export const DocumentInventoryTypeFragment = `
fragment DocumentInventoryTypeFragment on DocumentInventoryTypeConnection_DocumentInventoryType {
  nodes {
    id
    name
    state
  }
}
`;

export const documentInventoryTypesQuery = /* GraphQL */ `
  query {
    documentInventoryTypes {
      ...DocumentInventoryTypeFragment
      totalCount
    }
  }
  ${DocumentInventoryTypeFragment}
`;

export const fetchDocumentInventoryTypes = makeGqlCall<{
  documentInventoryTypes: {
    nodes: Array<{ id: string; name: string; state: string }>;
    totalCount: number;
  };
}>({
  query: documentInventoryTypesQuery,
  domain: Domain.DocumentManagement,
});

export const useDocumentInventoryTypes = () => {
  return useQuery(fetchDocumentInventoryTypes.name, () =>
    fetchDocumentInventoryTypes()
  );
};
