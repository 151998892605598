import Cookies from "js-cookie";

const parseErrors = (errors: unknown) =>
  Array.isArray(errors)
    ? errors
        .map((error) => (error && error.message) || null)
        .filter(Boolean)
        .join(".\n")
    : null;

export enum Domain {
  DocumentManagement = "ad.document-inventory.types.management",
}

type CallApiParams<T> = {
  method: "POST";
  url: string;
  domain?: Domain;
  body: T;
};

const callApi = async <TRes, TBody>({
  method,
  url,
  domain,
  body,
}: CallApiParams<TBody>) => {
  const accessToken = Cookies.get("t");

  const headers = {
    "access-domain": domain ?? "",
    accept: "application/json, text/plain, */*",
    "content-type": "application/json;charset=UTF-8",
    authorization: `Bearer ${accessToken}`,
  };

  const response = await fetch(url, {
    method,
    headers,
    body: JSON.stringify(body),
  });

  const res = await response.json();

  if (res.errors && res.errors.length) {
    throw parseErrors(res.errors);
  }

  return res as Promise<TRes>;
};

const gqlServiceUrl = "/api/v1/retail-graphql-gateway";

export const makeGqlCall =
  <TRes, TVars = void>({ query, domain }: { query: string; domain?: Domain }) =>
  (variables: TVars) =>
    callApi<{ data: TRes }, { query: string; variables: TVars }>({
      method: "POST",
      url: gqlServiceUrl,
      domain,
      body: { query, variables },
    });
