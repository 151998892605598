import { DocumentManagement } from "./pages/DocumentManagement";
import { Permission } from "./hooks";
import { DocumentManagementPermission } from "./hooks/useHasPermissions";

interface Route {
  path: string;
  component: JSX.Element;
  permissions: Permission[];
}

export const routePathByPage = {
  list: "/:lang/document-management",
};

export const routes: Array<Route> = [
  {
    path: routePathByPage.list,
    permissions: [DocumentManagementPermission.SearchDocumentInventoryTypes],
    component: <DocumentManagement />,
  },
];
