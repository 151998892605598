import { Helmet } from "react-helmet-async";

import { useSetState } from "react-use";
import { useHasPermissions } from "../../hooks";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Modal,
  PageHeader,
  Space,
  Table,
  Typography,
} from "antd";
import { DocumentManagementPermission } from "../../hooks/useHasPermissions";
import { InputField } from "./InputField";
import {
  useCreateDocumentInventoryType,
  useDisableDocumentInventoryType,
  useDocumentInventoryTypes,
  useEnableDocumentInventoryType,
} from "./hooks";

const DocumentInventoryTypeState = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

export const DocumentManagement = () => {
  const [{ showModal }, setState] = useSetState({
    showModal: false,
  });
  const canEdit = useHasPermissions()([
    DocumentManagementPermission.CreateDocumentInventoryType,
    DocumentManagementPermission.DisableDocumentInventoryType,
    DocumentManagementPermission.EnableDocumentInventoryType,
  ]);

  const { data } = useDocumentInventoryTypes();
  const totalCount = data?.data.documentInventoryTypes?.totalCount ?? 0;
  const nodes = data?.data.documentInventoryTypes?.nodes ?? [];

  const { mutateAsync: enableDocumentInventoryType } =
    useEnableDocumentInventoryType();
  const { mutateAsync: disableDocumentInventoryType } =
    useDisableDocumentInventoryType();

  const toggle = async (id: string, value: string) => {
    if (value === DocumentInventoryTypeState.DISABLED) {
      await enableDocumentInventoryType({ input: { id } });
    } else if (
      confirm(
        "Are you sure you want to disable this document type? The uploader will not support this type any longer."
      )
    ) {
      await disableDocumentInventoryType({ input: { id } });
    }
  };

  const { mutateAsync: createDocumentInventoryType } =
    useCreateDocumentInventoryType();
  const handleCreateDocumentInventoryType = async (input: { name: string }) => {
    await createDocumentInventoryType({ input });
    setState({
      showModal: false,
    });
  };

  const [form] = Form.useForm();

  return (
    <>
      <Modal
        onCancel={() =>
          setState({
            showModal: false,
          })
        }
        visible={showModal}
        title="New Document Item Type"
        data-qa-selector="document-shipping-addNewDocumentPopup"
        onOk={form.submit}
        okButtonProps={{
          ["data-qa-selector" as any]:
            "document-shipping-addNewDocumentPopupSubmitBtn",
        }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ name: "" }}
          onFinish={handleCreateDocumentInventoryType}
          size="large"
        >
          <InputField
            label="Add name"
            name="name"
            rules={[{ required: true, message: "This field is required." }]}
            required
            data-qa-selector="document-shipping-addNewDocumentInput"
          />
        </Form>
      </Modal>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Helmet title="Document Management" />
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0 }}
          title={
            <Typography.Title style={{ margin: 0 }}>
              Document Management
            </Typography.Title>
          }
          extra={
            <Button
              className="text-uppercase"
              type="primary"
              size="large"
              onClick={() => setState({ showModal: true })}
              data-qa-selector="document-shipping-addNewDocumentBtn"
              disabled={!canEdit}
            >
              Define new document items
            </Button>
          }
        />
        <Typography.Title level={4}>
          {totalCount} total result(s)
        </Typography.Title>
        <Table
          dataSource={nodes}
          pagination={false}
          data-qa-selector="document-shipping-documentTypeTable"
        >
          <Table.Column<typeof nodes[0]>
            title={<strong>Document Item</strong>}
            dataIndex="name"
            render={(name, { id }) => (
              <span data-qa-selector={`document-shipping-item-${id}`}>
                {name}
              </span>
            )}
          />
          <Table.Column<typeof nodes[0]>
            title={<strong>Action</strong>}
            dataIndex="id"
            render={(_, { state, id }) => (
              <span data-qa-selector={`document-shipping-checkInputCol-${id}`}>
                <Checkbox
                  name={id}
                  data-qa-selector={`document-shipping-checkInput-${id}`}
                  checked={state === DocumentInventoryTypeState.ENABLED}
                  value={state}
                  disabled={!canEdit}
                  onChange={(e) => toggle(id, e.target.value)}
                />{" "}
                {state === DocumentInventoryTypeState.ENABLED
                  ? "Enabled"
                  : "Disabled"}
              </span>
            )}
          />
        </Table>
      </Space>
    </>
  );
};
