import { useMemo } from "react";
import { usePermissions } from "./usePermissions";

export enum DocumentManagementPermission {
  CreateDocumentInventoryType = `gqls://ad.document-inventory.types.management/mutation/createdocumentinventorytype`,
  DisableDocumentInventoryType = "gqls://ad.document-inventory.types.management/mutation/disabledocumentinventorytype",
  EnableDocumentInventoryType = "gqls://ad.document-inventory.types.management/mutation/enabledocumentinventorytype",
  SearchDocumentInventoryTypes = "gqls://ad.document-inventory.types.management/query/documentinventorytypes",
}

export type Permission = DocumentManagementPermission;

const hasRequiredPermissions =
  (availablePermissions: string[]) => (types: string | string[]) =>
    [types].flat().some((type) => availablePermissions.includes(type));

export const useHasPermissions = () => {
  const { data: availablePermissions } = usePermissions();

  return useMemo(
    () => hasRequiredPermissions(availablePermissions ?? []),
    [availablePermissions]
  );
};

export default useHasPermissions;
