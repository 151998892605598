import { Input, InputProps, Form } from "antd";
import { Rule } from "antd/lib/form";

type InputFieldProps = {
  name: string;
  required?: boolean;
  rules?: Rule[];
  label: string;
  type?: InputProps["type"];
  dependencies?: string[];
  hasFeedback?: boolean;
  fullWidth?: boolean;
};

export const InputField = ({
  name,
  required,
  rules,
  label,
  type,
  hasFeedback,
  dependencies,
  fullWidth = true,
  ...rest
}: InputFieldProps) => (
  <Form.Item
    name={name}
    required={required}
    rules={rules}
    dependencies={dependencies}
    hasFeedback={hasFeedback}
    label={<span style={{ fontWeight: 400 }}>{label}:</span>}
  >
    <Input
      size="large"
      name={name}
      style={fullWidth ? { width: "100%" } : {}}
      placeholder={label}
      type={type}
      {...rest}
    />
  </Form.Item>
);
