import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { Domain, makeGqlCall } from "../../../apiClient";
import { fetchDocumentInventoryTypes } from "./useDocumentInventoryTypes";

export const createDocumentInventoryTypeQuery = /* GraphQL */ `
  mutation ($input: CreateDocumentInventoryTypeInput!) {
    createDocumentInventoryType(input: $input) {
      errors {
        message
      }
    }
  }
`;

const createDocumentInventoryType = makeGqlCall<
  unknown,
  { input: { name: string } }
>({
  query: createDocumentInventoryTypeQuery,
  domain: Domain.DocumentManagement,
});

export const useCreateDocumentInventoryType = () => {
  const queryClient = useQueryClient();

  return useMutation(createDocumentInventoryType, {
    onSuccess: ({ data }) => {
      message.success("New document defined");
      queryClient.invalidateQueries(fetchDocumentInventoryTypes.name);
    },
  });
};
