import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { Domain, makeGqlCall } from "../../../apiClient";
import {
  DocumentInventoryTypeFragment,
  fetchDocumentInventoryTypes,
} from "./useDocumentInventoryTypes";

export const enableDocumentInventoryTypeQuery = /* GraphQL */ `
  mutation ($input: EnableDocumentInventoryTypeInput!) {
    enableDocumentInventoryType(input: $input) {
      query {
        documentInventoryTypes {
          ...DocumentInventoryTypeFragment
        }
      }
    }
  }
  ${DocumentInventoryTypeFragment}
`;

const enableDocumentInventoryType = makeGqlCall<{}, { input: { id: string } }>({
  query: enableDocumentInventoryTypeQuery,
  domain: Domain.DocumentManagement,
});

export const useEnableDocumentInventoryType = () => {
  const queryClient = useQueryClient();
  return useMutation(enableDocumentInventoryType, {
    onSuccess: () => {
      message.success("Document inventory type was enabled");
      queryClient.invalidateQueries(fetchDocumentInventoryTypes.name);
    },
  });
};
