import { BrowserRouter } from "react-router-dom";
import { ConfigProvider, message, Space } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";

import { Routing } from "./Routing";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { ANTD_EXTERNAL_VERSION_LESS } from "./constants/antd";

message.config({
  top: 70,
  prefixCls: `${ANTD_EXTERNAL_VERSION_LESS}-message`,
});

const queryClient = new QueryClient();

export const Root = () => {
  return (
    <ConfigProvider prefixCls={ANTD_EXTERNAL_VERSION_LESS}>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <Helmet title="BO - Document Management" />
          <BrowserRouter>
            <Space
              direction="vertical"
              size="middle"
              style={{
                position: "relative",
                minWidth: "100vw",
                maxWidth: "100vw",
                padding: "20px",
              }}
            >
              <Routing />
            </Space>
          </BrowserRouter>
        </QueryClientProvider>
      </HelmetProvider>
    </ConfigProvider>
  );
};
